
import axios from 'axios'
import { Notify } from "vant";
const service = axios.create({
  baseURL: process.env.VUE_APP_BASE_API,
  timeout: 50000 // request timeout
})

// 请求拦截器
service.interceptors.request.use(
  config => {
    return config
  },
  error => {
    return Promise.reject(error)
  }
)

// -响应拦截器
service.interceptors.response.use(
  config => {
    const res = config.data
    /**
    * 10000=调用成功，message=success；
    * 10001=服务器发生未知错误，message=服务错误；
    * 10002=请求参数错误，message=错误描述；
    * 20001=未登录，message=没有登录；
    * 20002=没有权限，message=没有权限；
    * 30000=通用业务异常，message=业务异常描述，例如=用户不存在、名称重复
    * 自定义业务异常错误码：
    * 30001=提交工作时字段不存在；30002=系统正在一键还原中
    */

    if (res.code !== 10000) {
      if (res.code === 20001) {
        Notify({ type: "danger", message: '账号已过期，请退出重新登录' });
      } else {
        Notify({ type: "danger", message: res.message });
      }
      return Promise.reject(new Error(res.message || 'Error'))
    } else {
      return res
    }
  },
  error => {
    return Promise.reject(error)
  }
)

export default service



