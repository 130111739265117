<template>
  <div id="app">
    <router-view v-wechat-title="$route.meta.title" v-if="isShow" />
  </div>
</template>

<script>
export default {
  name: "App",
  provide() {
    return {
      reload: this.reload,
    };
  },
  data() {
    return {
      isShow: true,
    };
  },
  methods: {
    reload() {
      this.isShow = false;
      this.$nextTick(() => {
        this.isShow = true;
      });
    },
  },
};
</script>

<style lang="scss">
#app {
  width: 100%;
  min-height: 100vh;
}
</style>
