import request from './request'
import API from './api'

// 获取url的path以及query参数
export const queryPath = (url) => {
  url = url == null ? window.location.href : url
  let path = url.replace(/[?&]([^=&#]+)=([^&#]*)/ig, '')
  let search = url[0] === '?' ? url.substr(1) : url.substring(url.lastIndexOf('?') + 1)
  if (search === '') return {}
  search = search.split('&');
  let query = {};
  for (let i = 0; i < search.length; i++) {
    let pair = search[i].split('=');
    if (decodeURIComponent(pair[0]) != path) {
      query[decodeURIComponent(pair[0])] = decodeURIComponent(pair[1] || '')
    }
  }
  return {
    path,
    query
  };
}

/**
 * 
 * @param {*} api  请求地址的大写 
 * @param {*} type 请求类型
 * @param {*} data 参数
 * @param {*} responseType 其他参数
 * @returns 
 */
export const beseApi = (api, type, data, responseType) => {
  const re = {
    url: API[api] + '?code=' + responseType,
    method: type,
    [type == 'get' || type == 'GET' ? 'params' : 'data']: data,
    ...responseType,
  }
  return request(re)
}





