import Vue from 'vue'
import App from './App.vue'
import router from './router'
// 头部栏标题
import VueWechatTitle from 'vue-wechat-title';
Vue.use(VueWechatTitle);
// vant组件库
import Vant, { Lazyload } from 'vant';
import 'vant/lib/index.css';
Vue.use(Vant);
Vue.use(Lazyload);
// 导入api封装
import { beseApi } from './utils/index'
Vue.prototype.$api = beseApi
// 消除浏览器之间的基础样式差异
import "normalize.css"
Vue.config.productionTip = false
new Vue({
  router,
  render: h => h(App)
}).$mount('#app')

